<template>
  <v-dialog
    width="500"
    :value="dialog"
    @input="close"
  >
    <v-container
      fluid
      :style="style"
    >
      <v-row
        class="text-center secondary--text"
        style="background: #55855D"
      >
        <v-col>
          <v-container>
            <h3>{{ $t('rating.title') }}</h3>
          </v-container>
        </v-col>
      </v-row>
      <v-row
        class="text-center"
      >
        <v-col>
          <StarRating
            v-for="(questionKey, index) in starQuestionRange"
            :key="`${index}${refreshKey}`"
            :questionKey="questionKey"
          />
        </v-col>
      </v-row>
      <br>
      <TextRating
      />
      <SubmissionArea
        :disabled="!ratingComplete"
        :loading="submitting"
        @close="close"
        @submit="submit"
      />
    </v-container>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'Rating',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    StarRating: lazyLoad('components/Rating/StarRating'),
    TextRating: lazyLoad('components/Rating/TextRating'),
    SubmissionArea: lazyLoad('components/Dialogs/SubmissionArea'),
  },
  data: () => ({
    refreshKey: 0,
    submitting: false,
    starQuestionRange: ['1'],
  }),
  computed: {
    ...mapGetters('rating', [
      'stars',
    ]),
    ratingComplete() {
      if (this.stars) {
        return Object.keys(this.stars).length === this.starQuestionRange.length;
      }
      return false;
    },
    style() {
      return 'background-color: rgb(255, 255, 255)';
    },
  },
  methods: {
    ...mapActions('rating', [
      'reset',
      'postRatingData',
    ]),
    ...mapActions('email', [
      'sendRatingReportingEmail',
    ]),
    close() {
      this.$emit('close');
      this.refreshKey += 1;
      this.reset();
    },
    async submit() {
      this.submitting = true;
      try {
        this.$notify.info(this.$t('rating.processing'));
        await this.sendRatingReportingEmail();
        await this.postRatingData();
        this.$notify.success(this.$t('rating.thanks'));
      } catch (error) {
        this.$notify.error(error);
        throw new Error(error);
      } finally {
        this.submitting = false;
        await new Promise((resolve) => setTimeout(resolve, 2000));
        this.reset();
        this.close();
      }
    },
  },
};
</script>
